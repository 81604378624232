<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <b-img
        :src="appLogoImage"
        alt="logo"
        center
        width="200px"
        class="mb-5"
      />
      <div class="w-100 text-center">
        <h2
          v-if="!cancelling"
          class="mb-1"
        >
          Waiting... We impersonate you.
        </h2>
        <h2
          v-else
          class="mb-1"
        >
          Waiting... We cancel the impersonation.
        </h2>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app

    return {
      appLogoImage,
    }
  },
  data() {
    return {
      cancelling: false,
    }
  },
  mounted() {
    if (this.$route.query.action === 'cancel') {
      this.cancelling = true
    }

    this.$store.dispatch('auth/impersonateRedirectCallback')
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
